

const rooms = [{
    

    id: 5,
    guests: 4,
    beds: { 'bracni': 2, },
    size: "42 m²",
    floor: "2-kat",
    description: 'Air-conditioned apartment offering a seating area, a flat-screen satellite TV, a fully equipped kitchen and a private bathroom provides a shower and free toiletries. The balcony is fitted with table and chairs. The unit is set on the 2nd floor.',
    amenities1: ["private-entrance", "terrace", "dnevni", "air-conditioning", "tv","kitchen",],
    amenities2: ["private-bathroom", "shower", "free-toiletries", "towels", "linen"],
    amenities3: [ "refrigerator", "electric-kettle", "coffee-machine", "kitchenware", "ironing-facilities", "hairdryer"],

},
{

    id: 7,
    guests: 4,
    beds: { 'bracni': 1, 'single': 2 },
    size: "42 m²",
    floor: "2-kat",
    description: 'Ovaj apartman smješten je u prizemlju. Jedinica uključuje terasu s pogledom na vrt, potpuno opremljenu kuhinju i dnevni boravak s kaučom na rasklapanje za 1 osobu.',
    amenities1: ["private-entrance", "terrace", "dnevni", "air-conditioning", "tv","kitchen",],
    amenities2: ["private-bathroom", "shower", "free-toiletries", "towels", "linen"],
    amenities3: [ "refrigerator", "electric-kettle", "coffee-machine", "kitchenware", "ironing-facilities", "hairdryer"],
},
{

    id: 4,
    guests: 2,
    beds: { 'bracni': 1 },
    size: "42 m²",
    floor: "1-kat",
    description: 'Spacious air-conditioned apartment offering a seating area, a flat-screen satellite TV, a fully equipped kitchen and a private bathroom provides a shower and free toiletries. The apartment is fitted with 2 balconies. The unit is set on the 1st floor.',
    amenities1: ["private-entrance", "terrace", "dnevni", "air-conditioning", "heating","tv","kitchen",],
    amenities2: ["private-bathroom", "shower", "bidet", "free-toiletries", "towels", "linen"],
    amenities3: [ "refrigerator", "electric-kettle", "coffee-machine", "kitchenware", "ironing-facilities", "hairdryer"],


}

    ,
    {

    id: 3,
    guests:3,
    beds: { 'bracni': 1, 'single': 1 },
    size: "28 m²",
    floor: "prizemlje",
    description: 'Air-conditioned apartment offering a seating area, a flat-screen satellite TV, a fully equipped kitchen and a private bathroom provides a shower and free toiletries. The terrace is fitted with a table and chairs. The unit is set on the ground floor and has a separate entrance.',
    amenities1: ["private-entrance", "terrace", "dnevni", "air-conditioning", "tv","kitchen",],
    amenities2: ["private-bathroom", "shower", "free-toiletries", "towels", "linen"],
    amenities3: [ "refrigerator", "electric-kettle", "coffee-machine", "kitchenware", "ironing-facilities", "hairdryer"],
}
    ,
{
    id: 1,
    guests: 2,
    beds: { 'bracni': 1 },
    size: "16 m²",
    floor: "1-kat",
    description: 'Air-conditioned room offering satellite TV, a mini fridge and a private bathroom provides a shower. The balcony offers a seating area. The unit is set on the 1st floor.',
    amenities1: ["private-entrance", "terrace", "air-conditioning", "tv"],
    amenities2: ["private-bathroom", "shower", "free-toiletries", "towels", "linen"],
    amenities3: ["refrigerator", "electric-kettle", "hairdryer"]


},
{

    id: 2,
    guests: 2,
    beds: { 'bracni': 1, },
    size: "16 m²",
    floor: "1-kat",
    description: 'This double room has a balcony, air conditioning and electric kettle.',
    amenities1: ["private-entrance", "terrace", "air-conditioning", "tv"],
    amenities2: ["private-bathroom", "shower", "free-toiletries", "towels", "linen"],
    amenities3: ["refrigerator", "electric-kettle", "hairdryer"]
}, 


{

    id: 6,
    guests: 2,
    beds: { 'bracni': 1, },
    size: "17 m²",
    floor: "1-kat",
    description: 'Ovaj apartman smješten je u prizemlju. Jedinica uključuje terasu s pogledom na vrt, potpuno opremljenu kuhinju i dnevni boravak s kaučom na rasklapanje za 1 osobu.',
    amenities1: ["private-entrance", "air-conditioning", "tv"],
    amenities2: ["private-bathroom", "shower", "bidet", "free-toiletries", "towels", "linen"],
    amenities3: ["refrigerator", "electric-kettle", "hairdryer"]
}

];

export function getRooms() {
    return rooms;
}
export function getRoomById(id) {

    return rooms.filter(room => room.id === id)
}