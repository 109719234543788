import React from "react";
import { useIntl, Link, FormattedMessage } from "gatsby-plugin-intl";

import Layout from "../components/layout";
import parkingIcon from "../assets/imgs/parking.svg";
import wifiIcon from "../assets/imgs/wifi.svg";
import bikeIcon from "../assets/imgs/bike.svg";
import yearIcon from "../assets/imgs/year.svg";
import adultsIcon from "../assets/imgs/adults.svg";
import breakfastIcon from "../assets/imgs/breakfast.svg";

import { getRooms } from "../services/roomService";
import Slider from "../components/slider";
import { graphql } from "gatsby";
import Img from "gatsby-image";
import SEO from "../components/seo";

export default function Home({ data }) {
  const intl = useIntl();
  const rooms = getRooms();

  return (
    <Layout style={{ backgroundColor: "#F1FCFF" }}>
      <SEO lang={intl.locale} title="Home" />
      <div
        style={{ paddingBottom: "3rem" }}
        className="pocetna-hero"
      >
        <div
          className="hero-background pocetna"
          style={{ height: "calc(100vh - 89px" }}
        >
          <Img
            fluid={[
              data.pocetnaMob.childImageSharp.fluid,
              {
                ...data.pocetna.childImageSharp.fluid,
                media: `(min-width: 768px)`,
              },
            ]}
          />

        </div>
        <div className="content">
          <div className="card pocetna">
            <div>
              <h1>{intl.formatMessage({ id: "pocetna.naslov" })}</h1>
              <p style={{ marginBottom: "3rem" }}>
                <FormattedMessage
                  id="pocetna.about"
                  values={{
                    p: (...chunks) => <p>{chunks}</p>,
                    br: <br />,
                  }}
                />
              </p>
              <div className="row">
                <div className="col">
                  <img src={parkingIcon} alt="free parking" />
                  <p>{intl.formatMessage({ id: "pocetna.parking" })}</p>
                </div>
                <div className="col">
                  <img src={wifiIcon} alt="free wifi" />
                  <p>{intl.formatMessage({ id: "pocetna.wifi" })}</p>
                </div>
                <div className="col">
                  <img src={bikeIcon} alt="free bikes" />
                  <p>{intl.formatMessage({ id: "pocetna.bike" })}</p>
                </div>
                <div className="col">
                  <img src={yearIcon} alt="open all year" />
                  <p>{intl.formatMessage({ id: "pocetna.year" })}</p>
                </div>

              </div>
            </div>
          </div>
        </div>
      </div>
      <div className=" section" style={{ backgroundColor: "#F1FCFF" }}>
        <div className="content">
          <h2>{intl.formatMessage({ id: "jedinice" })}</h2>
          <Slider rooms={rooms} slike={data.slikeJedinica} />
        </div>
      </div>
      <div className=" section white pocetna-lokacija">
        <h2 className="content" style={{ marginBottom: "3rem" }}>{intl.formatMessage({ id: "lokacija" })}</h2>
        <div className="content row">
          <div className="col">
            <h3>{intl.formatMessage({ id: "rovinj" })}</h3>
            <p>
              <FormattedMessage
                id="pocetna.lokacija"
                values={{
                  p: (...chunks) => <p>{chunks}</p>,
                  br: <br />,
                }}
              />
            </p>
            <Link to="/lokacija">
              {intl.formatMessage({ id: "pocetna.o-lokaciji" })}
            </Link>
          </div>
          <div className="col">
            <Img fluid={data.map.childImageSharp.fluid} />
          </div>
        </div>
      </div>
    </Layout>
  );
}

export const query = graphql`
  query {
    pocetna: file(relativePath: { eq: "pocetna.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 2560, quality: 85) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    
    pocetnaMob: file(relativePath: { eq: "pocetna-mob.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 768, quality: 85) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    slikeJedinica: allFile(filter: { name: { eq: "1" } }) {
      edges {
        node {
          id
          childImageSharp {
            fluid(maxWidth: 500) {
              ...GatsbyImageSharpFluid
            }
          }
          relativeDirectory
        }
      }
    }
    map: file(relativePath: { eq: "map.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 768, quality: 90) {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
`;
